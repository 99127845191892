import React from 'react';
import { AppBar as MuiAppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useFirebaseAuth, auth, signOut } from '../firebase';

const AppBar = () => {
  const currentUser = useFirebaseAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareList = () => {
    console.log('Share list clicked');
    handleMenuClose();
  };

  const handleCreateNewList = () => {
    console.log('Create new list clicked');
    handleMenuClose();
  };

  const handleSignOut = () => {
    signOut(auth);
  };

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleShareList}>Share list</MenuItem>
          <MenuItem onClick={handleCreateNewList}>Create new list</MenuItem>
        </Menu>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Grocery List App
        </Typography>
        {currentUser ? (
          <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
        ) : (
          <Button color="inherit">Sign In</Button>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

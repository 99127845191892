import React from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const EditItemDialog = ({
  open,
  handleClose,
  editedItem,
  handleChange,
  handleAutocompleteChange,
  handleSave,
  storeGroupList,
  homeGroupList,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Edit Item</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        name="label"
        label="Item Label"
        value={editedItem.label}
        onChange={handleChange}
        fullWidth
      />
      <Autocomplete
        id="storegrouplabelsetter"
        freeSolo
        options={storeGroupList}
        onInputChange={(event, value) =>
          handleAutocompleteChange("storeGroup", value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            name="storeGroup"
            label="Store Group"
            value={editedItem.storeGroup}
            onChange={handleChange}
            fullWidth
          />
        )}
      />
      <Autocomplete
        id="homegrouplabelsetter"
        freeSolo
        options={homeGroupList}
        onInputChange={(event, value) =>
          handleAutocompleteChange("homeGroup", value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            name="homeGroup"
            label="Home Group"
            value={editedItem.homeGroup}
            onChange={handleChange}
            fullWidth
          />
        )}
      />
      <TextField
        margin="dense"
        name="note"
        label="Note"
        value={editedItem.note}
        onChange={handleChange}
        width="50%"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleSave}>Save</Button>
    </DialogActions>
  </Dialog>
);

export default EditItemDialog;

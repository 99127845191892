import React, { useState } from 'react';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import ItemListForReview from './ItemListForReview';

const NewRecipeDialogStep3 = ({recipe}) => {
  console.log("NewRecipeDialogStep3", recipe)
  return (
    <ItemListForReview items={recipe.outputProcessedItems} recipeId={recipe.id} recipeName={recipe.recipeName}/>
  );
};

export default NewRecipeDialogStep3;

import React from "react";
import { Box  } from "@mui/material";


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "center" }}>{children}</Box>
      )}
    </div>
  );
};

export default TabPanel;

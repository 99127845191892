import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Container,
} from "@mui/material";

import { useGroceryList } from "./GroceryListContext";
import RecipeList from "./RecipeList";
import ItemList from "./ItemList";

import { GroceryListProvider } from "./GroceryListContext";
import TabPanel from "./TabPanel";

const GroceryList = () => {
  const {
    items: groceryItems,
    onRequiredToggle,
    onPurchasedToggle,
    handleNewItem,
    handleDeleteItem,
    handleUpdateItem,
  } = useGroceryList();

  const [tabValue, setTabValue] = useState(0);
  const [showItemsRemaining, setShowItemsRemaining] = useState(true);
  const [itemFilter, setItemFilter] = React.useState(() => (x) => x);

  useEffect(() => {
    console.log("showItemsRemaining", showItemsRemaining, tabValue);
    if (tabValue === 1 && !showItemsRemaining) {
      setItemFilter(() => (x) => x.required);
    } else if (tabValue === 1 && showItemsRemaining) {
      console.log("showItemsRemaining", showItemsRemaining);
      setItemFilter(() => (x) => x.required && !x.purchased);
    } else {
      setItemFilter(() => (x) => x);
    }
  }, [tabValue, showItemsRemaining]);

  // when itemFilter changes, we want to filter the items
  const filteredItems = useMemo(() => {
    return groceryItems.filter(itemFilter);
  }, [groceryItems, itemFilter]);

  const handleShowItemsRemaining = () => {
    setShowItemsRemaining(!showItemsRemaining);
  };

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container sx={{maxWidth:420}}>
      <Box>
        <Box
          arialabel="tabs"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="sticky"
          top={0}
          zIndex={1000}
          bgcolor="background.default"
          px={2}
          py={1}
        >
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Home" />
            <Tab label="Store" />
            <Tab label="Recipes" />
          </Tabs>
        </Box>

        <Box marginTop={2}>
          <TabPanel value={tabValue} index={0}>
            <ItemList
              items={groceryItems}
              type="home"
              onRequiredToggle={onRequiredToggle}
              handleDeleteItem={handleDeleteItem}
              handleNewItem={handleNewItem}
              handleUpdateItem={handleUpdateItem}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box mb={2} display="flex" justifyContent="center">
              <ToggleButtonGroup
                value={showItemsRemaining ? "remaining" : "all"}
                exclusive
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    handleShowItemsRemaining({
                      target: { checked: newValue === "remaining" },
                    });
                  }
                }}
                aria-label="show items"
                sx={{
                  "& .MuiToggleButton-root": {
                    height: "32px", // Adjust button height
                    padding: "0 8px", // Adjust button padding
                    fontSize: "0.75rem", // Adjust font size
                  },
                }}
              >
                <ToggleButton
                  size="small"
                  value="all"
                  aria-label="show all items"
                >
                  Show all items
                </ToggleButton>
                <ToggleButton
                  value="remaining"
                  aria-label="hide purchased items"
                >
                  Hide purchased items
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <ItemList
              items={filteredItems}
              type="store"
              onRequiredToggle={onRequiredToggle}
              handleDeleteItem={handleDeleteItem}
              handleNewItem={handleNewItem}
              onPurchasedToggle={onPurchasedToggle}
              handleUpdateItem={handleUpdateItem}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <RecipeList/>
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

export default () => (
  <GroceryListProvider>
    <GroceryList />
  </GroceryListProvider>
);

import React, { useState } from 'react';
import {
  Button,
} from '@mui/material';
import UpdateRecipeDialog from './UpdateRecipeDialog';

const NewRecipeButton = () => {

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //setActiveStep(0);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New Recipe
      </Button>
      <UpdateRecipeDialog initialStep={0} open={open} handleClose={handleClose}/>
    </>
  );
};

export default NewRecipeButton;

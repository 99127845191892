import React, { useMemo, useState, useEffect } from "react";
import { List, ListSubheader, Box, Container } from "@mui/material";
import { sortBy } from "lodash";
import ItemCard from "./ItemCard";
import IconButton from "@mui/material/IconButton";
import NewItem from "./NewItem";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";

const GroupHeader = ({
  groupLabel,
  handleToggleAll,
  handleToggleAllOff,
  isHomeList,
}) => {
  const onToggleAll = () => {
    handleToggleAll(groupLabel);
  };

  const onToggleAllOff = () => {
    handleToggleAllOff(groupLabel);
  };

  return (
    <Container sx={{ maxWidth: 420 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <ListSubheader
          sx={{
            flex: 0,
            typography: "body1",
          }}
        >
          {groupLabel}
        </ListSubheader>
        {isHomeList && (
          <IconButton
            onClick={onToggleAll}
            color="primary"
            aria-label="select all"
          >
            <SelectAllIcon />
          </IconButton>
        )}
        {isHomeList && (
          <IconButton
            onClick={onToggleAllOff}
            color="primary"
            aria-label="deselect all"
          >
            <DeselectIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};

const Group = ({
  groupLabel,
  items,
  onRequiredToggle,
  onPurchasedToggle,
  handleDeleteItem,
  handleUpdateItem,
  handleNewItem,
  handleToggleAllInGroup,
  isHomeList,
  groupKey,
  storeGroupList,
  homeGroupList,
}) => {
  const handleToggleAll = () => {
    items.forEach((item) => {
      if (groupKey === "homeGroup") {
        handleUpdateItem(item.id, { required: true });
      } else if (groupKey === "storeGroup") {
        handleUpdateItem(item.id, { purchased: true });
      }
    });
  };

  const handleToggleAllOff = () => {
    items.forEach((item) => {
      if (groupKey === "homeGroup") {
        handleUpdateItem(item.id, { required: false });
      } else if (groupKey === "storeGroup") {
        handleUpdateItem(item.id, { purchased: false });
      }
    });
  };

  const handleUpdateGroupLabel = (newGroupLabel) => {
    items.forEach((item) => {
      handleUpdateItem(item.id, { [groupKey]: newGroupLabel });
    });
  };

  return (
    <React.Fragment key={groupLabel}>
      <Container sx={{ maxWidth: 420 }}>
        <GroupHeader
          groupLabel={groupLabel}
          handleToggleAll={handleToggleAll}
          handleToggleAllOff={handleToggleAllOff}
          isHomeList={isHomeList}
        />
        {items
          .filter((item) => item[groupKey] === groupLabel)
          .map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              homeStyle={isHomeList}
              storeStyle={!isHomeList}
              onRequiredToggle={onRequiredToggle}
              onPurchasedToggle={onPurchasedToggle}
              handleDeleteItem={handleDeleteItem}
              handleUpdateItem={handleUpdateItem}
              storeGroupList={storeGroupList}
              homeGroupList={homeGroupList}
            />
          ))}
        {isHomeList && (
          <NewItem
            storeGroupLabel="extras"
            homeGroupLabel={groupLabel}
            handleNewItem={handleNewItem}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

const ItemList = ({
  items,
  onRequiredToggle,
  onPurchasedToggle,
  handleDeleteItem,
  handleNewItem,
  handleUpdateItem,
  type,
  showItemsRemaining,
  storeGroupList,
}) => {
  const isHomeList = type === "home";

  const sortedItems = useMemo(() => {
    const preferredOrder = isHomeList
      ? ["weekly staples", "irregular", "staples to check"]
      : [];

    return sortBy(
      items,
      (item) => {
        const groupKey = isHomeList ? "homeGroup" : "storeGroup";
        const groupIndex = preferredOrder.indexOf(item[groupKey]);
        return groupIndex === -1 ? item[groupKey] : groupIndex;
      },
      "label"
    );
  }, [items, isHomeList]);

  const [groups, setGroups] = useState([]);
  const [homeGroups, setHomeGroups] = useState([]);
  const [storeGroups, setStoreGroups] = useState([]);

  // if the type is changed, update the groups
  useEffect(() => {
    setGroups(type === "home" ? homeGroups : storeGroups);
  }, [type, homeGroups, storeGroups]);

  // if the items change, update the groups
  useEffect(() => {
    setStoreGroups(
      sortedItems.reduce((acc, item) => {
        if (
          !acc.includes(item["storeGroup"]) &&
          !storeDefaultGroups.includes("storeGroup")
        ) {
          acc.push(item["storeGroup"]);
        }
        return acc;
      }, storeDefaultGroups)
    );
  }, [sortedItems]);

  useEffect(() => {
    setHomeGroups(
      sortedItems.reduce((acc, item) => {
        if (
          !acc.includes(item["homeGroup"]) &&
          !homeDefaultGroups.includes("homeGroup")
        ) {
          acc.push(item["homeGroup"]);
        }
        return acc;
      }, homeDefaultGroups)
    );
  }, [sortedItems]);

  const storeDefaultGroups = [];
  const homeDefaultGroups = ["Extras", "Weekly", "Staples"];
  const groupKey = isHomeList ? "homeGroup" : "storeGroup";

  const handleToggleAllInGroup = (groupLabel) => {
    const itemsInGroup = sortedItems.filter(
      (item) => item[groupKey] === groupLabel
    );
    itemsInGroup.forEach((item) =>
      isHomeList ? onRequiredToggle(item.id) : onPurchasedToggle(item.id)
    );
  };

  return (
    <List>
      {groups.map((groupLabel) => (
        <Group
          key={groupLabel}
          {...{
            groupLabel,
            items,
            onRequiredToggle,
            onPurchasedToggle,
            handleDeleteItem,
            handleUpdateItem,
            handleNewItem,
            handleToggleAllInGroup,
            isHomeList,
            groupKey,
            storeGroupList: storeGroups,
            homeGroupList: homeGroups,
          }}
          items={sortedItems.filter((item) => item[groupKey] === groupLabel)}
        />
      ))}
    </List>
  );
};

export default ItemList;

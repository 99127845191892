import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useGroceryListCollection,
  useGroceryListOperations,
} from "../firebase";

const GroceryListContext = createContext();

export const GroceryListProvider = ({ children }) => {
  const { collectionPath } = useParams();
  const { groceryItems, setGroceryItems } = useGroceryListCollection(collectionPath);
  const {
    onRequiredToggle,
    onPurchasedToggle,
    handleNewItem,
    handleUpdateItem,
    handleDeleteItem,
  } = useGroceryListOperations(collectionPath);

  return (
    <GroceryListContext.Provider
      value={{
        items: groceryItems,
        onRequiredToggle,
        onPurchasedToggle,
        handleNewItem,
        handleDeleteItem,
        handleUpdateItem
      }}
    >
      {children}
    </GroceryListContext.Provider>
  );
};

export const useGroceryList = () => {
  const context = useContext(GroceryListContext);
  if (!context) {
    throw new Error("useGroceryList must be used within a GroceryListProvider");
  }
  return context;
};

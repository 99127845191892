import React from 'react';
import { useCollectionLabels, useFirebaseAuth } from '../firebase';

const LandingPageWithAuth = () => {
  const { currentUser } = useFirebaseAuth();
  console.log("currentUser", currentUser);
  const collectionLabels = useCollectionLabels();

  console.log("collectionLabels", collectionLabels);
  return (
    <div>
      <h1>Welcome!</h1>
      <p>You have access to the following collections:</p>
      <ul>
        {collectionLabels.map((label, index) => (
          <li key={index}>{label}</li>
        ))}
      </ul>
    </div>
  );
};

export default LandingPageWithAuth;

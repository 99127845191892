import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Step,
  Stepper,
  StepLabel,
} from "@mui/material";
import { useRecipe, updateRecipe } from "../firebase";

import NewRecipeDialogStep1 from "./NewRecipeDialogStep1";
import NewRecipeDialogStep2 from "./NewRecipeDialogStep2";
import NewRecipeDialogStep3 from "./NewRecipeDialogStep3";

const UpdateRecipeDialog = ({
  open,
  handleClose,
  existingRecipeId,
  initialStep,
}) => {
  const [recipeId, setRecipeId] = useState(existingRecipeId);
  const [activeStep, setActiveStep] = useState(initialStep || 0);
  const collectionPath = "pat-and-lindsay";
  const { recipe, loading, error } = useRecipe(collectionPath, recipeId);
  const [status, setStatus] = useState("not_started");
  const [recipeText, setRecipeText] = useState("");

  useEffect(() => {
    console.log("UpdateRecipeDialog", recipeId, existingRecipeId, recipe, loading, error)
  }, [recipeId, recipe, loading, error])

  useEffect(() => {
    if (recipe) {
      setRecipeText(recipe.output);
      setStatus(recipe.status);
    }
  }, [recipe]);

  const handleNext = () => {
    console.log("handleNext", activeStep);
    if (activeStep === 1) {
      updateRecipe(collectionPath, recipeId, {
        output: recipeText,
        processingStage: "convert_text_to_items",
        status: "not_started",
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    "Upload photo",
    "Paste and edit recipe text",
    "Generate items",
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>New Recipe</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <NewRecipeDialogStep1 setRecipeId={setRecipeId} />}
        {activeStep === 1 && <NewRecipeDialogStep2 recipe={recipe} />}
        {activeStep === 2 && (
          <NewRecipeDialogStep3
            recipe={recipe}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button onClick={handleBack} color="primary">
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          color="primary"
          disabled={activeStep === steps.length - 1}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateRecipeDialog;

import React from "react";
import { Box, Container, Typography, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/signin");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, textAlign: "center" }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Good Shopping List
        </Typography>
        <Typography variant="body1" gutterBottom>
          Good Shopping List is a simple app that helps you manage your grocery
          list efficiently. Create your shopping list at home and access it
          easily while at the store.
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSignIn}>
            Sign In
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSignUp}>
            Sign Up
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default LandingPage;

import React, { useState, useEffect } from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { uploadImageToStorage, createRecipe } from "../firebase";
import { v4 as uuidv4 } from 'uuid';


const NewItemCard = ({ handleNewItem, homeGroupLabel, storeGroupLabel }) => {
  const [newItemLabel, setNewItemLabel] = useState("");

  const handleSaveItem = () => {
    if (newItemLabel.trim()) {
      handleNewItem({
        label: newItemLabel,
        required: false,
        purchased: false,
        homeGroup: homeGroupLabel,
        storeGroup: storeGroupLabel,
      });
      setNewItemLabel("");
    }
  };

  const handleLabelChange = (event) => {
    setNewItemLabel(event.target.value);
  };


  return (
    <ListItem>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <TextField
          fullWidth
          size="small"
          label="New Item"
          value={newItemLabel}
          onChange={handleLabelChange}
        />
      </Box>
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleSaveItem}>
          <AddIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default NewItemCard;

import * as React from "react";
import List from "@mui/material/List";
import ItemCard from "./ItemCard";
import { useGroceryListOperations } from "../firebase";

const ItemListForReview = ({ items, recipeId, recipeName }) => {
  const collectionPath = "pat-and-lindsay";
  const { handleNewItem, handleUpdateItem } =
    useGroceryListOperations(collectionPath);

  const handleAddItemFromRecipe = (item) => {
    
    const newItem = {
        label: item.label,
        required: true,
        purchased: false,
        storeGroup: "unknown",
        homeGroup: "Recipe",// + recipeName,
        //recipeId: [recipeId],
        quantity: 1,
        unit: "unit",
        };
    console.log("handleAddItemFromRecipe", newItem)
    handleNewItem(newItem);
    };  
  return (
    <List>
      {items.map((item) => (
        <ItemCard key={item.id} item={item} recipeReviewStyle={true} handleAddItemFromRecipe={handleAddItemFromRecipe}/>
      ))}
    </List>
  );
};

export default ItemListForReview;

import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import LandingPageNoAuth from "./LandingPageNoAuth";
import LandingPageWithAuth from "./LandingPageWithAuth";

const LandingPage = () => {
  const [user] = useAuthState(auth);

  return <>{user ? <LandingPageWithAuth /> : <LandingPageNoAuth />}</>;
};

export default LandingPage;

import React, { useState, useEffect } from "react";
import { TextField, CircularProgress, Box, Button } from "@mui/material";

const NewRecipeDialogStep2 = ({ recipeText, setRecipeText, recipe }) => {
  
  const loading = recipe.processingStage === "convert_image_to_text" && recipe.status !== "completed";

  const handleRecipeTextChange = (event) => {
    setRecipeText(event.target.value);
  };

  return (
    <Box position="relative">
      <TextField
        fullWidth
        multiline
        rows={10}
        label="Paste and edit recipe text"
        variant="outlined"
        value={recipe.output}
        onChange={handleRecipeTextChange}
        disabled={loading || (recipe && recipe.status === "pending")}
      />
      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 0.7)"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default NewRecipeDialogStep2;

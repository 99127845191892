import { initializeApp } from "firebase/app";
import { useState, useEffect } from "react";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  query,
  limit,
  orderBy,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBO27leS4Af279V3uAP5_syahmhf1865xY",
  authDomain: "goodshoppinglist.firebaseapp.com",
  projectId: "goodshoppinglist",
  storageBucket: "goodshoppinglist.appspot.com",
  messagingSenderId: "841785933814",
  appId: "1:841785933814:web:5eef5374037bea1882d1d7",
  measurementId: "G-5E5JG5P361",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

const createUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const signInUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signOutUser = () => {
  return signOut(auth);
};

const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

const useFirebaseAuth = () => {
  return useAuthState(auth);
};

const useItemCollections = (uid) => {
  const itemCollectionsRef = collection(firestore, "itemCollections");
  const q = query(itemCollectionsRef, orderBy("updatedAt", "desc"), limit(1));
  return useCollectionData(q, { idField: "id" });
};

export const useGroceryListCollection = (collectionPath) => {
  const [groceryItems, setGroceryItems] = useState([]);

  useEffect(() => {
    const groceryItemsRef = collection(
      firestore,
      `itemCollections/${collectionPath}/items`
    );

    const unsubscribe = onSnapshot(groceryItemsRef, (groceryItemsSnapshot) => {
      const items = groceryItemsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGroceryItems(items);
    });

    return () => {
      unsubscribe();
    };
  }, [collectionPath]);

  return { groceryItems, setGroceryItems };
};

// Add this hook to your firebase.js file
export const useGroceryListOperations = (collectionPath) => {
  const itemCollectionPath = `itemCollections/${collectionPath}/items`;

  const onRequiredToggle = async (itemId) => {
    const itemRef = doc(firestore, itemCollectionPath, itemId);
    const itemSnapshot = await getDoc(itemRef);
    const itemData = itemSnapshot.data();

    if (itemData) {
      const updatedItem = {
        ...itemData,
        required: !itemData.required,
      };
      await updateDoc(itemRef, updatedItem);
    }
  };

  const onPurchasedToggle = async (itemId) => {
    const itemRef = doc(firestore, itemCollectionPath, itemId);
    const itemSnapshot = await getDoc(itemRef);
    const itemData = itemSnapshot.data();

    if (itemData) {
      const updatedItem = {
        ...itemData,
        purchased: !itemData.purchased,
      };
      await updateDoc(itemRef, updatedItem);
    }
  };

  const handleNewItem = async (newItem) => {
    const itemCollectionRef = collection(firestore, itemCollectionPath);
    await addDoc(itemCollectionRef, newItem);
  };

  const handleUpdateItem = async (itemId, updatedItem) => {
    const itemRef = doc(firestore, itemCollectionPath, itemId);
    await updateDoc(itemRef, updatedItem);
  };

  const handleDeleteItem = async (itemId) => {
    const itemRef = doc(firestore, itemCollectionPath, itemId);
    await deleteDoc(itemRef);
  };

  return {
    onRequiredToggle,
    onPurchasedToggle,
    handleNewItem,
    handleUpdateItem,
    handleDeleteItem,
  };
};

export const signOut = async (auth) => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};

export const useCollectionLabels = () => {
  const [labels, setLabels] = useState([]);
  const [user] = useAuthState(auth);
  const uid = user?.uid;
  useEffect(() => {
    if (uid) {
      const unsubscribe = onSnapshot(
        collection(firestore, "itemCollections"),
        (querySnapshot) => {
          const userLabels = [];
          querySnapshot.forEach((doc) => {
            console.log("data", doc.data(), uid);
            // if the user has access (i.e uid in userAccess) to the collection, add the label to the list
            // if userAccess is undefined, then return []

            if (doc.data().userAccess?.includes(uid)) {
              userLabels.push(doc.data().label);
            }
          });
          setLabels(userLabels);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [uid]);

  return labels;
};

export const uploadImageToStorage = async (file, path) => {
  const storageRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(storageRef, file);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

export const createRecipe = async (collectionPath, recipeData) => {
  const recipeCollectionRef = collection(
    firestore,
    `itemCollections/${collectionPath}/recipes`
  );
  const recipe = await addDoc(recipeCollectionRef, recipeData);
  return recipe;
};

export const updateRecipe = async (collectionPath, recipeId, updatedData) => {
  const recipeRef = doc(firestore, `itemCollections/${collectionPath}/recipes/${recipeId}`);
  await updateDoc(recipeRef, updatedData);
};

export const getRecipe = async (collectionPath, recipeId) => {
  console.log(`itemCollections/${collectionPath}/recipes/${recipeId}`)
  const recipeDocRef = doc(firestore, `itemCollections/${collectionPath}/recipes/${recipeId}`);
  const recipeSnapshot = await getDoc(recipeDocRef);

  if (recipeSnapshot.exists()) {
    return { id: recipeSnapshot.id, ...recipeSnapshot.data() };
  } else {
    throw new Error(`Recipe with ID ${recipeId} not found.`);
  }
};


export const deleteRecipe = async (collectionPath, recipeId) => {
  const recipeRef = doc(firestore, `itemCollections/${collectionPath}/recipes/${recipeId}`);
  await deleteDoc(recipeRef);
};

export const useRecipes = (collectionPath) => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recipesRef = collection(
      firestore,
      `itemCollections/${collectionPath}/recipes`
    );

    const unsubscribe = onSnapshot(recipesRef, (recipesSnapshot) => {
      const recipeData = recipesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipes(recipeData);
      setLoading(false);
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [collectionPath]);

  return { recipes, loading };
};

export const useRecipe = (collectionPath, recipeId) => {
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const recipeRef = doc(firestore, `itemCollections/${collectionPath}/recipes/${recipeId}`);

    const unsubscribe = onSnapshot(
      recipeRef,
      (snapshot) => {
        setRecipe(snapshot.data());
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [collectionPath, recipeId]);

  return { recipe, loading, error };
};

export {
  auth,
  firestore,
  createUser,
  signInUser,
  signOutUser,
  resetPassword,
  useFirebaseAuth,
  useItemCollections,
  ref,
};

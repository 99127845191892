import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Checkbox,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditItemDialog from "./EditItemDialog";
import { useTheme } from "@mui/material/styles";
import IconButton from "./IconButton";

const ItemCard = ({
  item,
  homeStyle,
  storeStyle,
  recipeReviewStyle,
  handleAddItemFromRecipe,
  onRequiredToggle,
  onPurchasedToggle,
  handleDeleteItem,
  handleUpdateItem,
  storeGroupList,
  homeGroupList,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editedItem, setEditedItem] = useState({
    label: item.label,
    homeGroup: item.homeGroup,
    storeGroup: item.storeGroup,
  });

  const handleRequiredToggle = () => {
    onRequiredToggle(item.id);
  };

  const handlePurchasedToggle = () => {
    onPurchasedToggle(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    setEditedItem({
      ...editedItem,
      [event.target.name]: event.target.value,
    });
  };

  const handleAutocompleteChange = (name, value) => {
    setEditedItem({
      ...editedItem,
      [name]: value,
    });
  };

  const handleSave = () => {
    console.log("saving", editedItem);
    handleUpdateItem(item.id, editedItem);
    handleClose();
  };
  console.log("item", item);

  return (
    <Container>
      <ListItem sx={{ maxWidth: 420 }}>
        {storeStyle && (
          <Checkbox
            edge="start"
            checked={item.purchased}
            onChange={handlePurchasedToggle}
            inputProps={{ "aria-labelledby": `checkbox-list-label-${item.id}` }}
          />
        )}
        <Box display="flex" alignItems="center">
          <ListItemText
            primary={item.label}
            secondary={item.note}
            sx={{
              "& .MuiListItemText-secondary": {
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.body2.fontWeight,
                lineHeight: theme.typography.body2.lineHeight,
              },
            }}
          />
        </Box>
        <ListItemSecondaryAction>
          {homeStyle && (
            <IconButton edge="end" onClick={handleRequiredToggle}>
              <ShoppingCartIcon
                sx={{
                  color: item.required ? theme.palette.success.main : "#797D81",
                  marginRight: 1,
                }}
              />
            </IconButton>
          )}
          {recipeReviewStyle && (
            <IconButton edge="end" onClick={() => handleAddItemFromRecipe(item)}>
              <ShoppingCartIcon
                sx={{
                  color: item.required ? theme.palette.success.main : "#797D81",
                  marginRight: 1,
                }}
              />
            </IconButton>
          )}
          {!recipeReviewStyle && (
            <>
              <IconButton edge="end" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" onClick={() => handleDeleteItem(item.id)}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <EditItemDialog
        open={open}
        handleClose={handleClose}
        editedItem={editedItem}
        handleChange={handleChange}
        handleAutocompleteChange={handleAutocompleteChange}
        handleSave={handleSave}
        storeGroupList={storeGroupList}
        homeGroupList={homeGroupList}
      />
    </Container>
  );
};

export default ItemCard;

import "./App.css";
import GroceryList from "./components/GroceryList";
import LandingPage from "./components/LandingPage";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import AppMenu from "./components/AppMenu";
import ForgotPassword from "./components/ForgotPassword";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*
Todo:
[*] make the New Item button work
[*] make the New Item button work in a group
[*] allow users to edit the group of an item
[*] add a way to edit items
[*] fix UX a bit
  [*] make the purhcased filter work
  [*] make the group headers not wrap
  [*] find a cooler theme
[*] allow users to delete items
[*] get routing working
[*] make the width of the list not be so wide
[ ] try opening the camera from the app
[*] add auth
[ ] bulk add items from a note delimited by newlines or commas
[ ] sign out
[ ] add a way to share lists
[ ] add a quick add button as a fab button
[ ] make it so the new item button suggest existing items if they are close to the search term
[ ] allow users to create their own groups, and rename groups (in both store and home lists)
[ ] iterate on the theme
[ ] home page for creating new collections
[ ] useRecipes has pat-and-lindays hard coded (lots of places do
[ ] nename NewRecipe... to UpdateRecipe thigs like that
[ ] in the upddate recipe workflow, allow the user to replace the image but show the existing one

*/

// When the user goes to url.com/:collectionPath, we want to render the GroceryList component with the GroceryListProvider

function App() {
  return (
    <div className="App">
      <Router>
        <AppMenu />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/:collectionPath" element={<GroceryList />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { uploadImageToStorage, createRecipe } from "../firebase";
import { v4 as uuidv4 } from "uuid";

const NewRecipeDialogStep1 = ({setRecipeId}) => {
  const [file, setFile] = useState(null);

  const collectionPath = "pat-and-lindsay";

  const handleCaptureImage = (event) => {
    console.log("handleCaptureImage", event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadImage = async () => {
    if (file) {
      const path = `itemCollections/${collectionPath}/photos/${uuidv4()}-${
        file.name
      }`;
      try {
        const imageURL = await uploadImageToStorage(file, path);
        console.log("Image uploaded successfully, URL:", imageURL);

        // Create a recipe document with the image URL and status
        const recipe = await createRecipe(collectionPath, {
          imageUrl: imageURL,
          processingStage: "convert_image_to_text",
          status: "not_started",
          type: "image_to_text",
        });
        setRecipeId(recipe.id);
        console.log("Recipe created successfully, ID:", recipe.id);
      } catch (error) {
        console.log("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    if (file) {
      handleUploadImage();
    }
  }, [file]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <input
        accept="image/*"
        id="icon-button-photo"
        type="file"
        capture="environment"
        style={{ display: "none" }}
        onChange={(event) => handleCaptureImage(event)}
      />
      <label htmlFor="icon-button-photo">
        <IconButton edge="end" component="span">
          <PhotoCameraIcon fontSize="large" />
        </IconButton>
      </label>
      <p>Click the icon above to upload a photo of the recipe</p>
    </Box>
  );
};

export default NewRecipeDialogStep1;

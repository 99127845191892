import React, { useState } from "react";
import { useRecipes } from "../firebase";
import RecipeCard from "./RecipeCard";
import "./RecipeList.css";
import NewRecipeButton from "./NewRecipeButton";

const RecipeList = () => {
  const collectionPath = "pat-and-lindsay";
  const { recipes, loading } = useRecipes(collectionPath);

  const [expandedRecipeId, setExpandedRecipeId] = useState(null);

  console.log("recipes", recipes);

  const toggleExpand = (recipeId) => {
    if (expandedRecipeId === recipeId) {
      setExpandedRecipeId(null);
    } else {
      setExpandedRecipeId(recipeId);
    }
  };

  return (
    <div className="recipe-list">
      <NewRecipeButton/>
      {!loading && recipes.map((recipe) => <RecipeCard collectionPath={collectionPath} {...recipe}/>)}
    </div>
  );
};

export default RecipeList;

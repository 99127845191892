import React from 'react';
import { IconButton } from '@mui/material';

const SecondaryIconButton = ({ children, color = 'secondary', ...other }) => {
  return (
    <IconButton color={color} {...other}>
      {children}
    </IconButton>
  );
};

export default SecondaryIconButton;

export const themeOptions = {
    palette: {
      type: 'light',
      primary: {
        main: '#262033',
      },
      secondary: {
        main: '#797D81',
      },
      success: {
        main: '#53A548',
      },
    },
    typography: {
      h1: {
        fontFamily: 'Roboto',
      },
      fontFamily: 'Roboto',
      body1: {
        fontFamily: 'Roboto',
      },
      body2: {
        fontFamily: 'Lato',
      },
    },
    spacing: 8,
    shape: {
      borderRadius: 2,
    },
    props: {
      MuiAppBar: {
        color: 'secondary',
      },
    },
  };